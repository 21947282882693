<template>
    <div class="share-detail-wrapper">
        <div class="share-detail-cover flex-box-column">
            <div class="hd">
                <van-nav-bar left-arrow safe-area-inset-top title="分享详情" @click-left="back"></van-nav-bar>
                <div class="type-change">
                    <div class="type-btn" @click="typeChange(0)"  :class="{active:showIndex==0}">转发</div>
                    <div class="type-btn" @click="typeChange(1)"  :class="{active:showIndex==1}">查看</div>
                </div>
            </div>
            <div class="bd f-scroll-view">
                  <div class="share-cover" v-if="showIndex == 0">
                      <template v-for="(item,index) in shareData">
                          <van-cell 
                          title="单元格" 
                          value="内容" 
                          :key="index" 
                          v-if="shareData && shareData.length>0">
                            <template #icon>
                                <div class="thumb-cover">
                                    <img :src="item.headimgurl" alt="">
                                </div>
                            </template>
                          </van-cell>
                      </template>
                      <van-empty description="暂无转发数据" v-if="inited && shareData.length==0" />
                  </div>
                  <div class="share-cover" v-if="showIndex == 1">
                      <template v-for="(item,index) in visitData">
                          <van-cell :title="item.nickname" :value="item.num" :label="item.create_time" :key="index" v-if="visitData && visitData.length>0" >
                            <template #icon>
                                <div class="thumb-cover">
                                    <img :src="item.headimgurl" alt="">
                                </div>
                            </template>
                          </van-cell>
                      </template>
                      <van-empty description="暂无查看数据" v-if="inited && visitData.length==0" />
                  </div>
            </div>
        </div>
    </div>
</template>
<script>
import backMixin from "@/mixins/back";
import api from "@/api"
export default {    

    mixins:[backMixin],

    props:['id'],

    data(){

        return{

            inited:false,

            list:{},

            showIndex:0

        }

    },

    computed:{

        shareData(){

            return this.list?.shareData || []

        },

        visitData(){

            return this.list?.visitData || []

        }

    },

    methods:{

        getUserShareStatistics(){

            this.$toast.loading({

                message:'正在加载...'

            })

            api.getUserShareStatistics({

                options:{

                    id:this.id

                },

                success: (res)=> {

                    this.list = res.data;
                    
                },

                error: (err)=>{

                    console.log(err);

                    this.$toast.fail("获取失败");

                },

                complete: ()=>{

                    this.$toast.clear();

                    this.inited = true;
                    
                }

            })

        },

        typeChange(index){

            this.showIndex = index;

        }

    },

    created(){

        this.$nextTick(()=>{

            this.getUserShareStatistics();

        })

    }

}
</script>

<style lang="less" scoped>

    .share-detail-wrapper{

        &,.share-detail-cover{

            height:100%;

            .type-change{

                background: #f6f6f6;

                height:45px;

                line-height:45px;

                padding: 5px;

                display: flex;

                width: 95%;

                margin: 10px auto;

                border-radius:5px;
                
                .type-btn{

                    flex:1;

                    height:100%;
                    
                    display: flex;

                    justify-content: center;

                    align-items: center;

                    font-size:14px;

                    border-radius:5px;

                    &.active{

                        background: #fff;

                    }

                }

            }

            .van-cell{

                align-items: center;

            }

            .thumb-cover{

                height:40px;

                width: 40px;

                margin-right:10px;

                img{

                    width: 100%;

                    height:100%;

                    border-radius: 50%;

                }

            }

        }

    }

</style>